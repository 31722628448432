import { useState, useEffect } from "react";
import { useRecoilState } from 'recoil';
import { ShowModal } from './recoil/atoms/General';
import Modal from 'react-bootstrap/Modal';
import mixpanel from 'mixpanel-browser';

function WaitlistModal(props) {


   useEffect(() => {

      mixpanel.init('1f1fa41102c9d600ee5c1f968fd179d6', { debug: true, ignore_dnt: true });
      mixpanel.track('AttendEase', { 'Page': "WaitlistModal" });

   }, []);

   const [Show_WL_Modal, setShow_WL_Modal] = useRecoilState(ShowModal);

   const [FormNo, setFormNo] = useState(2);

   const [show, setShow] = useState(Show_WL_Modal);
   const handleClose = () => setShow(false);

   const [Email, setEmail] = useState("");

   const [Name, setName] = useState("");
   const [InstitutionName, setInstitutionName] = useState("");
   const [PhoneNo, setPhoneNo] = useState("");
   // const [Message, setMessage] = useState("");


   const Post_Email = async (R_Type = 0) => {

      if (Email === "") {
         alert("Please enter valid E-Mail address");
         return false;
      }

      let Pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/;
      let U_Email_Status = Pattern.test(Email);
      if (!(U_Email_Status)) {
         alert("Please enter valid email address");
         return false;
      }

      if (PhoneNo !== "" && PhoneNo.length !== 10) {
         alert("Please enter valid 10 digit mobile number");
         return false;
      }


      const req_data = new FormData();
      req_data.append("Email", Email);
      req_data.append("Name", Name);
      req_data.append("InstitutionName", InstitutionName);
      req_data.append("PhoneNo", PhoneNo);

      const response = await fetch("https://stage.edumerge.com/V2/AttendEase/Post.php", {
         method: "POST",
         body: req_data
      });

      const res_Data = await response.json();

      // setMessage(res_Data.Message);

      let Data = res_Data?.Data;

      if (Data.Email !== "" && Data.Email !== undefined) {
         setEmail(Data.Email);
      }

      if (Data.Name !== "" && Data.Name !== undefined) {
         setName(Data.Name);
      }

      if (Data.InstitutionName !== "" && Data.InstitutionName !== undefined) {
         setInstitutionName(Data.InstitutionName);
      }

      if (Data.PhoneNo !== "" && Data.PhoneNo !== undefined) {
         setPhoneNo(Data.PhoneNo);
      }

      if (res_Data.Status === 2) {
         setFormNo(2);
         mixpanel.track('WaitList registration complete', { 'Page': "HowItWorks" });
      } else if (res_Data.Status === -1) {
         setFormNo(-1);
      }

      if (R_Type === 1) {
         setShow(false);
         setShow_WL_Modal(false);
      }

   }

   return (
      <div className="WaitlistModal">
         {
            show === true
               ?
               <div className="bs5">
                  <Modal
                     show={show}
                     onHide={handleClose}
                     size="sm"
                     aria-labelledby="contained-modal-title-vcenter"
                     centered keyboard={false}
                     backdrop="static">

                     <Modal.Body>

                        <div className="" style={{ "text-align": "right" }}>
                           <i onClick={() => {
                              setShow(false);
                              setShow_WL_Modal(false);
                           }} className="fas fa-times"></i>
                        </div>
                        <iv className="p-1">
                           {/* 
                           {
                              FormNo === 1
                                 ?
                                 <div className="">
                                    <label for="exampleInputEmail1" className="form-label"> <b> Your Email </b> </label>
                                    <input
                                       type="email"
                                       value={Email}
                                       className="form-control"
                                       onChange={(e) => {
                                          setEmail(e.target.value);
                                       }}
                                       onBlur={() => {
                                          Post_Email();
                                       }}
                                    />
                                    
                                 </div>
                                 :
                                 null
                           } */}

                           {
                              FormNo === 2
                                 ?
                                 <div className="">
                                    <div className="my-1">
                                       <label for="exampleInputEmail1" className="form-label"> <b> Your Email <span style={{ "color": "red" }}> * </span> </b> </label>
                                       <input
                                          type="email"
                                          value={Email}
                                          className="form-control"
                                          onChange={(e) => {
                                             setEmail(e.target.value);
                                          }}
                                          onBlur={() => {
                                             Post_Email();
                                          }}
                                       />
                                       <div className="p-1 CustFont"> Please provide your details so that we can contact you for further updates. </div>
                                    </div>

                                    <div className="my-1">
                                       <label for="exampleInputEmail1" className="form-label"> <b> Name </b> </label>
                                       <input
                                          type="text"
                                          value={Name}
                                          className="form-control"
                                          onChange={(e) => {
                                             setName(e.target.value);
                                          }}
                                          onBlur={() => {
                                             Post_Email();
                                          }}
                                       />
                                    </div>
                                    <div className="my-1">
                                       <label for="exampleInputEmail1" className="form-label"> <b> Institution Name </b> </label>
                                       <input
                                          type="text"
                                          value={InstitutionName}
                                          className="form-control"
                                          onChange={(e) => {
                                             setInstitutionName(e.target.value);
                                          }}
                                          onBlur={() => {
                                             Post_Email();
                                          }}
                                       />
                                    </div>
                                    <div className="my-1">
                                       <label for="exampleInputEmail1" className="form-label"> <b> Mobile No </b> </label>
                                       <input
                                          type="text"
                                          value={PhoneNo}
                                          className="form-control"
                                          onChange={(e) => {
                                             setPhoneNo(e.target.value);
                                          }}
                                          onBlur={() => {
                                             Post_Email();
                                          }}
                                       />
                                    </div>
                                 </div>
                                 :
                                 null
                           }

                           {
                              FormNo === -1
                                 ?
                                 <div class="alert alert-danger" role="alert">
                                    Apologies, it seems the origin is unknown. Please try again.
                                 </div>
                                 :
                                 null
                           }

                           {
                              FormNo === 3
                                 ?
                                 <div class="alert alert-success" role="alert">
                                    Your WaitList registration is complete.
                                 </div>
                                 :
                                 null
                           }

                        </iv>

                        <hr></hr>
                        <button className="btn btn-primary BtnColor" onClick={() => {
                           Post_Email(1);
                        }}>
                           <i className="fas fa-check-circle"></i> &nbsp; Submit
                        </button>
                     </Modal.Body>



                  </Modal>
               </div>
               :
               null
         }


      </div>
   );
}
export default WaitlistModal;