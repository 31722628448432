import React, { useEffect } from 'react';
import "../css/App.css";
import { useRecoilState } from 'recoil';
import { ShowModal } from './recoil/atoms/General';
import mixpanel from 'mixpanel-browser';

function ContactUs() {

   useEffect(() => {

      mixpanel.init('1f1fa41102c9d600ee5c1f968fd179d6', { debug: true, ignore_dnt: true });
      mixpanel.track('AttendEase', { 'Page': "ContactUs" });

   }, []);

   const [Show_WL_Modal, setShow_WL_Modal] = useRecoilState(ShowModal);
   return (
      <div
         onWheel={(e) => {
            if (e.deltaY > 0) {

            } else {
               const section = document.querySelector('.FeaturesPageStart');
               section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
         }}
         className="ContactUs" id="ContactUsPage">

         <div className="PoweredPage mt-3">
            <div className="d-flex align-items-center">
               <div className="flex-fill">

               </div>
               <div className="px-3">
                  <div className="Brand-Heading-Text-Color Animation-Letter">
                     AttendEase
                  </div>
               </div>
               <div className="px-3">
                  <img className="PoweredBy-Logo" src={"./images/x.png"} alt="PoweredBy" />
               </div>
               <div className="px-3">
                  <a href="https://www.edumerge.com" target="_blank">
                     <img className="PoweredBy-Logo" src={"https://www.edumerge.com/images/edumerge_logo_name_white.png"} style={{ "height": "40px" }} alt="PoweredBy" />
                  </a>
               </div>
               <div className="flex-fill">

               </div>
            </div>
         </div>

         <hr></hr>

         <div className="p-5">
            <div className="row">
               <div className="col-sm">
                  <div className="mt-3 Address">
                     <i className="fa fa-map-marker-alt"></i> &nbsp; No. T-2, 3rd Floor, Farhaan Center, Langford Rd Cross, Richmond Town, Bengaluru Karnataka 560025
                  </div>
                  <div className="mt-3">
                     <i className="fas fa-envelope"></i>
                     &nbsp;
                     <a className="ColorGray" href="mailto:info@edumerge.ai"> info@edumerge.ai </a>
                  </div>
                  <div className="mt-3">
                     <i className="fas fa-envelope"></i>
                     &nbsp;
                     <a className="ColorGray" href="mailto:sales@edumerge.ai"> sales@edumerge.ai </a>
                  </div>
               </div>
               <div className="col-sm-4 mt-3">

               </div>
               <div className="col-sm-4 mt-3">
                  <div>
                     <div>
                        <h4> Join our Waitlist </h4>
                     </div>
                     <div className="text-left">
                        Embrace and join the ranks of educational institutions that are redefining attendance management.
                     </div>
                     <div className="my-3">
                        <button type="button" className="btn WaitList-Btn-1" onClick={() => {
                           setShow_WL_Modal(true);
                           mixpanel.track('Wait list Clicked ', { 'Page': "ContactUs" });
                        }}>
                           Join Now
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            <hr></hr>
            <div className="bottom-footer">
               <div className="row align-items-center">
                  <div className=" text-center col-sm-12 mb-2">
                     <div className="footer-copyright">
                        <p>Copyright © <span id="copy_write_current_year">2023</span> AttendEase | <a href="https://www.edumerge.com/"> edumerge </a> All Rights Reserved </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default ContactUs;