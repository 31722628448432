import { useState, useEffect } from "react";
import "../css/App.css";
import mixpanel from 'mixpanel-browser';

function HowItWorks() {

   useEffect(() => {

      mixpanel.init('1f1fa41102c9d600ee5c1f968fd179d6', { debug: true, ignore_dnt: true });
      mixpanel.track('AttendEase', { 'Page': "HowItWorks" });

   }, []);

   const [Point_1, setPoint_1] = useState("svg");
   const [Point_2, setPoint_2] = useState("svg");
   const [Point_3, setPoint_3] = useState("svg");
   const [Point_4, setPoint_4] = useState("svg");

   const [Point1, setPoint1] = useState("Point1");
   const [Point2, setPoint2] = useState("Point2");
   const [Point3, setPoint3] = useState("Point3");
   const [Point4, setPoint4] = useState("Point4");

   const [Vertical_Border, setVertical_Border] = useState("Vertical_Border");


   return (
      <div>
         <div className="HowItWorks" id="HowItWorksPage">

            <div className="Page3-Headings my-5">
               How does it work?
            </div>

            <div className="Page3-Background">
               <div className="Page3-Top-Shade"></div>
               <div className="Page3-Content">

                  <div className="row mt-5">

                     <div className="col-sm">

                        <div className="Page3-Steps-Left">

                           <div
                              onMouseOver={() => {
                                 setPoint_1("gif");
                              }}
                              onMouseOut={() => {
                                 setPoint_1("svg");
                              }}
                              className="Page3-Point1">
                              <div className="row Page3-Heading">
                                 <div className="col-2">
                                    01.
                                 </div>
                                 <div className="col-10">
                                    Customise and scale at no additional cost
                                 </div>
                              </div>

                              <div className="row Page3-Desc">
                                 <div className="col-2">

                                 </div>
                                 <div className="col-10">
                                    Easily streamline your attendance system end to end by integrating with Leave Management and Payroll.
                                 </div>
                              </div>
                           </div>

                           <div
                              onMouseOver={() => {
                                 setPoint_3("gif");
                              }}
                              onMouseOut={() => {
                                 setPoint_3("svg");
                              }}
                              className="Page3-Point3">
                              <div className="row Page3-Heading">
                                 <div className="col-2">
                                    03.
                                 </div>
                                 <div className="col-10">
                                    Manage Attendance & Substitution effectively.
                                 </div>
                              </div>

                              <div className="row Page3-Desc">
                                 <div className="col-2">

                                 </div>
                                 <div className="col-10">
                                    Easily streamline your attendance system end to end by integrating with Leave Management and Payroll.
                                 </div>
                              </div>

                           </div>
                        </div>

                     </div>

                     <div className={"col-sm-1 " + Vertical_Border}>
                        <div className={"text-center " + Point1}>
                           <img src={"./images/Page3-Pointer." + Point_1} className="PointerSize" alt="Vertical Border" />
                        </div>

                        <div className={"text-center " + Point2}>
                           <img src={"./images/Page3-Pointer." + Point_2} className="PointerSize" alt="Vertical Border" />
                        </div>

                        <div className={"text-center " + Point3}>
                           <img src={"./images/Page3-Pointer." + Point_3} className="PointerSize" alt="Vertical Border" />
                        </div>

                        <div className={"text-center " + Point4}>
                           <img src={"./images/Page3-Pointer." + Point_4} className="PointerSize" alt="Vertical Border" />
                        </div>
                     </div>

                     <div className="col-sm">

                        <div className="Page3-Steps-Right">

                           <div
                              onMouseOver={() => {
                                 setPoint_2("gif");
                              }}
                              onMouseOut={() => {
                                 setPoint_2("svg");
                              }}
                              className="Page3-Point2">
                              <div className="row Page3-Heading">
                                 <div className="col-2">
                                    02.
                                 </div>
                                 <div className="col-10">
                                    Create an institute profile & self onboard users
                                 </div>
                              </div>

                              <div className="row Page3-Desc">
                                 <div className="col-2">

                                 </div>
                                 <div className="col-10">
                                    Easily streamline your attendance system end to end by integrating with Leave Management and Payroll.
                                 </div>
                              </div>
                           </div>

                           <div
                              onMouseOver={() => {
                                 setPoint_4("gif");
                              }}
                              onMouseOut={() => {
                                 setPoint_4("svg");
                              }}
                              className="Page3-Point4">
                              <div className="row Page3-Heading">
                                 <div className="col-2">
                                    04.
                                 </div>
                                 <div className="col-10">
                                    Integrate with Leave Management and Payroll
                                 </div>
                              </div>

                              <div className="row Page3-Desc">
                                 <div className="col-2">

                                 </div>
                                 <div className="col-10">
                                    Easily streamline your attendance system end to end by integrating with Leave Management and Payroll.
                                 </div>
                              </div>
                           </div>

                        </div>

                     </div>
                  </div>

                  <div className="row mb-5 text-center">
                     <div className="col-sm-4">

                     </div>
                     <div className="col-sm-4">
                        <h4>
                           Ready to use!
                        </h4>
                     </div>
                     <div className="col-sm-4">

                     </div>
                  </div>

               </div>
               <div className="Page3-Bottom-Shade"></div>
            </div>
         </div>
         <hr></hr>
      </div>
   );
}
export default HowItWorks;