import { useState, useEffect } from "react";
import { useRecoilState } from 'recoil';
import "../css/App.css";
import { ShowModal } from './recoil/atoms/General';
import mixpanel from 'mixpanel-browser';

function Features() {

   useEffect(() => {

      mixpanel.init('1f1fa41102c9d600ee5c1f968fd179d6', { debug: true, ignore_dnt: true });
      mixpanel.track('AttendEase', { 'Page': "Features" });

   }, []);

   const [Show_WL_Modal, setShow_WL_Modal] = useRecoilState(ShowModal);
   const [FeatureClass, setFeatureClass] = useState("col-sm-4");
   const [FeatureDisplay, setFeatureDisplay] = useState("");
   const [FeatureData, setFeatureData] = useState([]);

   const [FPS_PR, setFPS_PR] = useState("FeaturesPageStart-PR");

   let Features = [];
   let Temp_Array = {
      'Heading': "Efficient Attendance Tracking",
      'Image': "Attendance-Tracking",
      'Content': [
         {
            'Heading': "Accuracy",
            "Image": "Accuracy",
            'Description': "Ensures precise attendance tracking by creating virtual boundaries around designated areas."
         },
         {
            'Heading': "Real Time Tracking",
            "Image": "Real-Time-Tracking",
            'Description': "Attendance is tracked in real-time as employees enter or exit the designated location."
         },
         {
            'Heading': "Convenience",
            "Image": "Convenience",
            'Description': "Skip the Queues & enjoy hassle-free attendance marking,"
         }
      ]
   };
   Features[1] = Temp_Array;

   Temp_Array = {
      'Heading': "Zero Cost on Maintenance & Operations",
      'Image': "Zero-Cost",
      'Content': [
         {
            'Heading': "Cost Savings",
            "Image": "Cost-Savings",
            'Description': "Implementing Geo-fencing can lead to cost savings by reducing the need for traditional attendance tracking methods, such as physical time clocks or manual registers."
         },
         {
            'Heading': "Zero Hardware Investment",
            "Image": "Minimal-Hardware-Investment",
            'Description': "Geo-fencing relies on GPS-enabled devices such as smartphones, which are typically owned by individuals. This eliminates the need for investing in specialized hardware for attendance tracking."
         },
         {
            'Heading': "Scalability at Zero Cost",
            "Image": "Scalability-at-Zero-Cost",
            'Description': "If the organization grows, Geo-fencing solutions can often scale up seamlessly without significant additional costs, making it cost-effective for expanding operations."
         }
      ]
   };
   Features[2] = Temp_Array;

   Temp_Array = {
      'Heading': "Automatic Data Consolidation",
      'Image': "Automatic-Data-Consolidation",
      'Content': [
         {
            'Heading': "Automated Record Keeping",
            "Image": "Automated-Record-Keeping",
            'Description': "Attendance data is automatically recorded and stored electronically, reducing the administrative burden of manual record keeping."
         },
         {
            'Heading': "Reduced Administrative Burden",
            "Image": "Reduced-Administrative-Burden",
            'Description': "The automation of data consolidation reduces the need for manual data entry, spreadsheet management, and report creation, freeing up administrative resources for more strategic tasks."
         },
         {
            'Heading': "Instant Accessibility",
            "Image": "Instant-Accessibility",
            'Description': "With centralized data storage, authorized personnel can access attendance records anytime and from anywhere, enhancing operational flexibility."
         }
      ]
   };
   Features[3] = Temp_Array;

   Temp_Array = {
      'Heading': "Simplified Reports, Analysis & Notifications",
      'Image': "Simplified-Reports-Notifications",
      'Content': [
         {
            'Heading': "Remote Management",
            "Image": "Remote-Management",
            'Description': "Managers can monitor attendance remotely and receive alerts for attendance anomalies, ensuring prompt action and maintaining attendance integrity."
         },
         {
            'Heading': "Data Analytics",
            "Image": "Data-Analytics",
            'Description': "Geo-fencing technology generates valuable data that can be analyzed to identify attendance patterns, optimize resource allocation, and make informed decisions for process improvement."
         },
         {
            'Heading': "Instant Notifications",
            "Image": "Instant-Notifications",
            'Description': "Enables instant notifications to be sent to administrators or supervisors when an employee or student enters or exits a designated area."
         }
      ]
   };
   Features[4] = Temp_Array;

   Temp_Array = {
      'Heading': "API Integration",
      'Image': "API-Integration",
      'Content': [
         {
            'Heading': "Seamless Data Flow",
            "Image": "Seamless-Data-Flow",
            'Description': "API Application Programming Interface integration allows Geo-fencing systems to communicate and share data with other software applications, ensuring a seamless flow of attendance data between different platforms."
         },
         {
            'Heading': "HR and Payroll Integration",
            "Image": "HR-and-Payroll-Integration",
            'Description': "APIs enable integration with HR and payroll software, streamlining the process of payroll calculation based on accurate attendance data and reducing manual data entry."
         },
         {
            'Heading': "Customized Solutions",
            "Image": "Customized-Solutions",
            'Description': "API integration offers the flexibility to create customized solutions tailored to an organization's unique needs, integrating attendance data into existing software environments."
         }
      ]
   };
   Features[5] = Temp_Array;

   Temp_Array = {
      'Heading': "Teacher Substitution",
      'Image': "Teacher-Substitution_1",
      'Content': [
         {
            'Heading': "Consistency",
            "Image": "Consistency",
            'Description': "Automatic teacher substitutions ensure a consistent approach to handling teacher absences. The system can follow predefined rules and criteria to select substitutes, maintaining continuity in lesson plans and teaching styles."
         },
         {
            'Heading': "Enhanced Communication",
            "Image": "Enhanced-Communication",
            'Description': "Substitutions allows teachers, substitutes, and school administrators to stay connected and informed about schedule changes, and important updates, fostering better collaboration and coordination."
         },
         {
            'Heading': "Optimized Resource Allocation:",
            "Image": "Optimized-Resource-Allocation",
            'Description': "Substitutions allow administrators to efficiently allocate substitute teachers to classrooms based on qualifications, subject expertise, and availability. This optimization ensures that the right substitute is assigned to the right class."
         }
      ]
   };
   Features[6] = Temp_Array;


   let FeatureDesc = [];
   Temp_Array = {
      'Heading': "Teacher Substitution",
      'Image': "Teacher-Substitution",
      'FeaturesNo': 6,
      'Desc': "Streamline the process, ensuring a seamless transition for teachers and students while optimizing substitute placement based on qualifications and availability."
   }
   FeatureDesc.push(Temp_Array);

   Temp_Array = {
      'Heading': "Efficient Attendance Tracking",
      'Image': "Efficient-Attendance-Tracking",
      'FeaturesNo': 1,
      'Desc': "Effortlessly monitor attendance with pinpoint accuracy, real-time tracking, and skip-the-queue convenience."
   }
   FeatureDesc.push(Temp_Array);

   Temp_Array = {
      'Heading': "Zero Cost on Maintenance & Operations",
      'Image': "Zero-Maintenance-Operations",
      'FeaturesNo': 2,
      'Desc': "Offers Cost-Savings through hardware-free attendance tracking that scales effortlessly as your organization grows."
   }
   FeatureDesc.push(Temp_Array);

   Temp_Array = {
      'Heading': "Automatic Data Consolidation",
      'Image': "AutomaticDataConsolidation",
      'FeaturesNo': 3,
      'Desc': "Automated attendance record-keeping streamlines administrative tasks, provides instant access to data, & reduces manual workloads."
   }
   FeatureDesc.push(Temp_Array);

   Temp_Array = {
      'Heading': "Simplified Reports, Analysis & Notifications",
      'Image': "Simplified-Reports-Analysis",
      'FeaturesNo': 4,
      'Desc': "Provides real-time data insights and immediate alerts for better attendance management and informed decision-making."
   }
   FeatureDesc.push(Temp_Array);

   Temp_Array = {
      'Heading': "API Integration",
      'Image': "Integration",
      'FeaturesNo': 5,
      'Desc': "Smooth exchange of data across various platforms, simplifies HR & payroll, allows personalized solutions to match your requirements."
   }
   FeatureDesc.push(Temp_Array);

   return (
      <div onMouseLeave={() => {
         setFeatureDisplay("");
         setFeatureData([]);
         setFeatureClass("col-sm-4");
         setFPS_PR("FeaturesPageStart-PR");
      }} className={"FeaturesPageStart " + FPS_PR} id="FeaturesPage">
         <div className="row">
            <div className="col-lg">
               <div className="">
                  <div className="Page2-Heading px-3 pt-3">
                     Our Features
                  </div>
                  <div className="row">
                     <div className="Page2-SubHeading col-lg-8 p-3 ">
                        Expect industry frustrations and challenges to be systematically addressed and resolved as we tackle them one by one.
                     </div>
                  </div>
               </div>

               <div className="row">
                  {
                     FeatureDesc.map((FeatureData, index) => {
                        return (
                           <>
                              <div className={FeatureClass + " p-3"}>
                                 <div className="row">
                                    <div className="col-sm-2">
                                       <div className="text-center animate_fadeIn">
                                          <img src={"./images/" + FeatureData.Image + ".png"} className="FeaturesLogo" alt="" />
                                       </div>
                                    </div>
                                    <div className="col-sm-10">
                                       <div className="animate_fadeIn">
                                          <span className="Page2-Feature-Heading"> {FeatureData.Heading} </span>
                                       </div>

                                       <div className="animate_fadeIn mt-3">
                                          <span className="Page2-Feature-Desc"> {FeatureData.Desc} </span>
                                       </div>

                                       <div className="animate_fadeIn mt-3">
                                          <div className="LearnMore" onClick={() => {
                                             setFeatureDisplay(FeatureData.FeaturesNo);
                                             setFeatureData([Features[FeatureData.FeaturesNo]]);
                                             setFeatureClass("col-sm-6");
                                             setFPS_PR("");
                                             mixpanel.track('Learn More Clicked', { 'Page': "Features" });
                                             const section = document.querySelector('.FeaturesPageStart');
                                             section.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                          }}> Learn More </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </>
                        )
                     })
                  }
               </div>

               <div className="mt-2 py-3" style={{ "margin-left": "80px" }}>
                  <button type="button" className="WaitList-Btn-3 mb-5" onClick={() => {
                     setShow_WL_Modal(true);
                     mixpanel.track('Wait list Clicked ', { 'Page': "Features" });
                  }}>
                     Join the Waitlist
                  </button>
               </div>
            </div>

            {
               FeatureDisplay !== ""
                  ?
                  <div className="col-lg-4 card">
                     <div className="d-flex flex-wrap">
                        {
                           FeatureData.map((FeatureData, index) => {
                              return (
                                 <div className="">
                                    <div className="text-center mt-5">
                                       <img src={"./images/" + FeatureData.Image + ".png"} className="CardImage" alt="" />
                                    </div>

                                    <div className="animate_fadeIn my-2 text-center FeatureData-Text-Color">
                                       <span className="Page2-Highlight"> {FeatureData.Heading} </span>
                                    </div>

                                    {
                                       FeatureData.Content.map((FeaturePoints, index2) => {
                                          return (
                                             <div className="p-2">
                                                <div className="row animate_fadeIn">
                                                   <div className="col-2 text-center">
                                                      <img src={"./images/" + FeaturePoints.Image + ".png"} className={"FeaturePoints_Image " + FeaturePoints.Image} alt="" />
                                                   </div>
                                                   <div className="col-10">
                                                      <div className="FeaturePoints_Headings">
                                                         <b> {FeaturePoints.Heading} </b>
                                                      </div>
                                                      <div className="FeaturePoints_Data my-2">
                                                         {FeaturePoints.Description}
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          )
                                       })
                                    }
                                 </div>
                              )

                           })
                        }
                     </div>
                  </div>
                  :
                  null
            }

         </div>
      </div >
   )
}
export default Features;