import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import "../css/App.css";
import { ShowModal } from './recoil/atoms/General';
import WaitlistModal from "./WaitlistModal";
import mixpanel from 'mixpanel-browser';

const Device_Width = window.innerWidth;
let Device = "";
let BG_Class1 = "";
let BG_Class2 = "";
if (Device_Width > 768) {

   Device = "Desktop";
   BG_Class1 = "Page1Bg";
   BG_Class2 = "p-5";

} else {
   Device = "Mobile";
   BG_Class1 = "mb-5";
   BG_Class2 = "p-3";

}
function Landing() {

   useEffect(() => {

      mixpanel.init('1f1fa41102c9d600ee5c1f968fd179d6', { debug: true, ignore_dnt: true });
      mixpanel.track('AttendEase', { 'Page': "Landing" });

   }, []);

   const [Show_WL_Modal, setShow_WL_Modal] = useRecoilState(ShowModal);

   return (
      <div>
         <div className="LandingPage" id="LandingPage">
            {/* Menus */}
            <div div className="Menus p-3">
               <div className="d-flex">
                  <div className="flex-grow-1 px-3">
                     <div className="Brand-Heading-Text-Color Animation-Letter">
                        AttendEase
                     </div>
                  </div>
                  {
                     Device === "Desktop"
                        ?
                        <>
                           <div className="px-3 mt-3 Menu-Btn">
                              <a href="#LandingPage"> Home </a>
                           </div>
                           <div className="px-3 mt-3 Menu-Btn">
                              <a href="#FeaturesPage"> Features </a>
                           </div>
                           <div className="px-3 mt-3 Menu-Btn">
                              <a href="#HowItWorksPage"> How it Works </a>
                           </div>
                           <div className="px-3 mt-3 Menu-Btn">
                              <a href="#ContactUsPage"> Contact Us </a>
                           </div>
                           <div className="mt-2 px-3 text-center" onClick={() => {
                              setShow_WL_Modal(true);
                              mixpanel.track('Wait list Clicked ', { 'Page': "Landing" });
                           }}>
                              <button type="button" className="btn WaitList-Btn-1">
                                 Join the Waitlist
                              </button>
                           </div>
                        </>
                        :
                        null
                  }
               </div>
            </div>

            {
               Show_WL_Modal === true
                  ?
                  <WaitlistModal />
                  :
                  null
            }

            {/* Left Page Data */}
            <div className={BG_Class1}>
               <div className={BG_Class2}>
                  <br>
                  </br>
                  <div className="Heading-Text">
                     <div>
                        Attendance
                     </div>
                     <div>
                        Management
                     </div>
                  </div>
                  <div className="Heading-Text-Color Animation-Letter">
                     Reimagined for <br></br> Educational <br></br> Institutions
                  </div>
                  <div className="mt-4 Intro-Para">
                     <div className="row">
                        <div className="col-sm-5">
                           <div>
                              Revolutionize your attendance management experience with <br></br> no more data woes and unnecessary queues. You can streamline your processes, increase efficiency, and improve accuracy using Geo-Fencing technology.
                           </div>
                           <div className="mt-5">
                              <div className="mt-3">
                                 <button type="button" className="btn WaitList-Btn-2 px-5" onClick={() => {
                                    setShow_WL_Modal(true);
                                    mixpanel.track('Wait list Clicked ', { 'Page': "Landing" });
                                 }}>
                                    Join the Waitlist Now <i className="fas fa-arrow-right fa-sm"></i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div >
         </div >
         <div className='Page2'>
            <div className='row Page2-PadTop'>
               <div className='col-lg Page2-PadLeft'>
                  <div className='Page2-HeadingFont'>
                     Revisiting the way <br></br>
                     we manage <br></br>
                     Staff’s Attendance
                  </div>
                  <div className='my-5 Page2-SubHeadingFont'>
                     Attendance is more than just marking, it is a system can help <br></br> you streamline other related tasks.
                  </div>
               </div>
               <div className='col-lg'>
                  <div className='mt-4'>
                     <div className='pl-5 Page2-Points'>
                        <ul>
                           <li>
                              Get centralised view of all the teacher’s availability
                           </li>
                           <li>
                              Effective UI that helps in staff allocation and planning
                           </li>
                           <li>
                              Auto-reconciliation of attendance marking with Leave Management and Payrolls.
                           </li>
                        </ul>
                     </div>
                     <div className='text-center pt-5'>
                        <img src="./images/Page2-Col2-Bottom.png"></img>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
export default Landing;