import Landing from './landing';
import Features from './Features';
import HowItWorks from './HowItWorks';
import ContactUs from './ContactUs';

function App() {
  return (
    <div className="App">
      <Landing />
      <Features />
      <HowItWorks />
      <ContactUs />
    </div>
  );
}
export default App;